<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col">
          <api-hits-record-table
            title="Api Tracking"
            :tableData="users"
          ></api-hits-record-table>
        </div>
      </div>
      <div class="mt-2">
        <base-pagination
          
          :page-count="totalPages"
          v-model="currentPage"
          align="end"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import ApiHitsRecordTable from "../Tables/ApiHitsRecordTable"; 

import * as api from "@/apiList";
export default {
  name: "Users",
  components: {
    ApiHitsRecordTable
  },
   data() {
    return {
      userid: this.$route.params.userid,
      users: [],
      user:{},
      currentPage: 1,
      totalPages: 0,
      limit:20,
    };
  },
  methods: {
    async getapicall(page) {
      try {
        console.log(this.userid);
        const res = await api.getapicall(this.userid , page , this.limit ); 
        if (res) {
        this.users = res.getrecord.body.hits.hits;
        const cal = res.totalrecords.body.count;        
        this.totalPages = cal/this.limit;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getapicall(this.currentPage);
  },
   watch: {
    currentPage: function (val) {
      this.getapicall(val);
    },
  },
};
</script>
<style></style>
