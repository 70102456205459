<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <form role="form" style="width: 400px; margin-top: 50px">
        <base-input
          alternative
          class="mb-3"
          label="Role Name"
          placeholder="Role Name"
          input-classes="form-control-alternative"
          v-model="role.role_name"
        ></base-input>
        <base-checkbox class="mb-3" v-model="isShowServices">
          Show Services Table
        </base-checkbox>
        <div class="text-center mb-3">
          <model-list-select
            :list="services"
            v-model="selectService"
            option-value="_id"
            option-text="name"
            placeholder="Selected Services"
          >
          </model-list-select>
        </div>
        <base-checkbox class="mb-3" v-model="isShowEndpoints">
          Show Endpoints Table
        </base-checkbox>
        <div class="text-center mb-3">
          <model-list-select
            :list="apiEndpoints"
            v-model="selectItem"
            option-value="_id"
            option-text="api_endpoint"
            placeholder="Selected Endpoints"
          >
          </model-list-select>
        </div>
        <base-checkbox class="mb-3" v-model="isShowRoutes">
          Show Frontend Routes Table
        </base-checkbox>
        <div class="text-center">
          <model-list-select
            :list="frontendRoutes"
            v-model="selectFrontendRoute"
            option-value="_id"
            option-text="route"
            placeholder="Selected Frontend Routes"
          >
          </model-list-select>
        </div>
      </form>
    </div>

    <div class="container-fluid mt-5" v-if="isShowServices">
      <div class="row">
        <div class="col">
          <selected-services-table
            title="Selected Services"
            :tableData="selectedServices"
            @deleteSelectedService="deleteSelectedService"
          ></selected-services-table>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5" v-if="isShowEndpoints">
      <div class="row">
        <div class="col">
          <selected-endpoints-table
            title="Selected Endpoints"
            :tableData="selectedApiEndpoints"
            @deleteSelectedEndpoint="deleteSelectedEndpoint"
          ></selected-endpoints-table>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5" v-if="isShowRoutes">
      <div class="row">
        <div class="col">
          <selected-frontend-routes-table
            title="Selected Frontend Routes"
            :tableData="selectedFrontendRoutes"
            @deleteSelectedFrontendRoute="deleteSelectedFrontendRoute"
          ></selected-frontend-routes-table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <base-button
        id="button-add"
        type="primary"
        class="my-4"
        @click="saveRole"
        >{{ id ? "Update Role" : "Add Role" }}</base-button
      >
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";
import { ModelListSelect } from "vue-search-select";
import SelectedEndpointsTable from "../Tables/SelectedEndpointsTable";
import SelectedFrontendRoutesTable from "../Tables/SelectedFrontendRoutesTable";
import SelectedServicesTable from "../Tables/SelectedServicesTable";
import lodash from "lodash";
export default {
  name: "CreateApiEndpoints",
  components: {
    SelectedEndpointsTable,
    SelectedFrontendRoutesTable,
    SelectedServicesTable,
    ModelListSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      role: {},
      apiEndpoints: [],
      frontendRoutes: [],
      services: [],
      selectedApiEndpoints: [],
      selectedApiEndpointsIds: [],
      selectedFrontendRoutes: [],
      selectedFrontendRoutesIds: [],
      selectedServices: [],
      selectedServicesIds: [],
      selectItem: "",
      selectFrontendRoute: "",
      selectService: "",
      isShowEndpoints: true,
      isShowRoutes: true,
      isShowServices: true,
    };
  },
  methods: {
    async getRole() {
      const res = await api.getRole(this.id);
      if (res && res.data) {
        let role = res.data.role;
        this.selectedApiEndpointsIds = role.allowed_api_routes.map(
          (api) => api._id
        );
        this.selectedFrontendRoutesIds = role.allowed_frontend_routes.map(
          (api) => api._id
        );

        this.selectedServicesIds = role.allowed_services.map(
          (api) => api._id
        );
        this.selectedApiEndpoints = role.allowed_api_routes;
        this.selectedFrontendRoutes = role.allowed_frontend_routes;
        this.selectedServices = role.allowed_services;
        this.role = {
          role_name: role.role_name,
        };
      }
    },

    async getApiEndpoints() {
      const res = await api.getAllApiEndpoints();
      if (res && res.data) {
        this.apiEndpoints = res.data.apiEndpoint;
      }
    },

    async getFrontendRoutes() {
      const res = await api.getAllFrontendRoute();
      if (res && res.data) {
        this.frontendRoutes = res.data.frontendRoute;
      }
    },
    async getAllServices() {
      const res = await api.getAllServices();
      if (res && res.data) {
        this.services = res.data.service;
      }
    },

    async saveRole() {
      let res;
      this.role.allowed_api_routes = this.selectedApiEndpointsIds;
      this.role.allowed_frontend_routes = this.selectedFrontendRoutesIds;
      this.role.allowed_services = this.selectedServicesIds;
      if (this.id) {
        res = await api.editRole(this.id, this.role);
      } else {
        res = await api.addRole(this.role);
      }
      if (res.success) {
        this.$confirm({
          title: "Success",
          message: this.id ? "Role Successfully Updated" : "Role Successfully Created",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Roles" });
          },
        });
      } else {
        this.$confirm({
          title: "Error",
          message: "Role Error",
          button: {
            yes: "OK",
          },
        });
      }
    },

    deleteSelectedEndpoint(index) {
      this.selectedApiEndpointsIds.splice(index, 1);
      this.selectedApiEndpoints.splice(index, 1);
    },

    deleteSelectedFrontendRoute(index) {
      this.selectedFrontendRoutesIds.splice(index, 1);
      this.selectedFrontendRoutes.splice(index, 1);
    },

    deleteSelectedService(index) {
      this.selectedServicesIds.splice(index, 1);
      this.selectedServices.splice(index, 1);
    }
  },
  mounted() {
    this.getApiEndpoints();
    this.getFrontendRoutes();
    this.getAllServices();
    if (this.id) {
      this.getRole();
    }
  },
  watch: {
    selectItem: function (val) {
      const itemId = this.selectedApiEndpointsIds.indexOf(val);

      if (itemId === -1) {
        const item = lodash.find(this.apiEndpoints, { _id: val });
        this.selectedApiEndpoints.push(item);
        this.selectedApiEndpointsIds.push(val);
      }
    },
    selectFrontendRoute: function (val) {
      const itemId = this.selectedFrontendRoutesIds.indexOf(val);

      if (itemId === -1) {
        const item = lodash.find(this.frontendRoutes, { _id: val });
        this.selectedFrontendRoutes.push(item);
        this.selectedFrontendRoutesIds.push(val);
      }
    },
    selectService: function (val) {
      const itemId = this.selectedServicesIds.indexOf(val);

      if (itemId === -1) {
        const item = lodash.find(this.services, { _id: val });
        this.selectedServices.push(item);
        this.selectedServicesIds.push(val);
      }
    },
  },
};
</script>
<style></style>
