<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <router-link to="/create-frontend-routes" class="col-2 text-right">
          <base-button id="button-add" type="primary">Add Route</base-button>
        </router-link>
        <div class="col-2 text-right">
          <base-button id="button-add" type="primary" @click="fileChooser"
            >Import</base-button
          >
        </div>
      </div>
    </div>
 <input class="form-control" type="text"
        v-model="search" placeholder="Search" aria-label="Search"/>
    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>ID</th>
          <th>Name</th>
          <th>Route</th>
          <th>Service</th>
          <th>Action</th>
        </template>

        <template slot-scope="{ row, index }">
          <th scope="row">{{ index + 1 }}</th>
          <td class="budget">{{ row.title }}</td>
          <td>{{ row.route }}</td>

          <td>{{ row.service.name }}</td>

          <td class="budget">
            <span @click="editItemClick(row)">
              <i class="fa fa-edit"></i>
            </span>
            <span
              @click="deleteApiFrontendRoute(row, index)"
              style="margin-left: 10px"
            >
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";

export default {
  name: "frontend-routes-table",
  props: {
    type: {
      type: String,
    },
    tableData: Array,
    title: String,
  },
  data() {
    return {
      deleteModal: false,
      method: "Select Method",
      search:""  
    };
  },
  watch: {
        search: function (search) {
           this.$emit("dataUpdate" , 1, search);
        }
      },
  mounted() {},
  methods: {
    async deleteApiFrontendRoute(frontendRoute) {
      this.$confirm({
        auth: false,
        title: "Delete Frontend Route",
        message: `Are you sure you want to delete Frontend Route?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm == true) {
            await api.deleteFrontendRoute(frontendRoute._id);
            this.$emit("dataUpdate","");
            this.$confirm({
              title: "Success",
              message: "Route Deleted Successfully",
              button: {
                yes: "OK",
              },
            });
          }
        },
      });
    },
    editItemClick(frontendRoute) {
      this.$router.push({
        name: "Edit Frontend Routes",
        params: { id: frontendRoute._id },
      });
    },
    async fileChooser() {
      try {
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";
        input.onchange = async (e) => {
          if (parseInt(e.target.files.length) > 1) {
            alert("You can only upload a single JSON file");
          } else {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);
            try {
              let res = await api.importRoutesJson(formData);
              if (res) {
                this.$emit("dataUpdate");
                this.$confirm({
                  title: "Success",
                  message: "Api Endpoints imported successfully",
                  button: {
                    yes: "OK",
                  },
                });
              }
            } catch (error) {
              alert(error.response.data.message);
            }
          }
        };
        input.click();
      } catch (e) {
        alert(e.message);
      }
    },
  },
};
</script>
<style>
</style>
