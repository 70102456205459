<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>ID</th>
          <th>Name</th>
          <th>Route</th>
          <th>Method</th>
          <th>Action</th>
        </template>

        <template slot-scope="{ row, index }">
          <th scope="row">{{ index + 1 }}</th>
          <td class="budget">{{ row.title }}</td>
          <td>{{ row.api_endpoint }}</td>

          <td>{{ row.api_method }}</td>

          <td class="budget">
            <span
              @click="deleteSelectedApiEndpoint(index)"
              style="margin-left: 10px"
            >
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
// import * as api from "@/apiList";

export default {
  name: "selected-endpoints-table",
  props: {
    type: {
      type: String,
    },
    tableData: Array,
    title: String,
  },
  data() {
    return {
      deleteModal: false,
      method: "Select Method",
    };
  },
  mounted() {},
  methods: {
    deleteSelectedApiEndpoint(index) {
      this.$confirm({
        auth: false,
        title: "Delete Endpoint",
        message: `Are you sure you want to delete endpoint?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm == true) {
            this.$emit("deleteSelectedEndpoint", index);
          }
        },
      });
    },
  },
};
</script>
<style>
</style>
