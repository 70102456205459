<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <form role="form" style="width: 400px; margin-top: 50px">
        <base-input
          alternative
          class="mb-3"
          label="Api Endpoint Name"
          placeholder="Name"
          input-classes="form-control-alternative"
          v-model="apiEndpoint.title"
        ></base-input>
        <base-input
          alternative
          class="mb-3"
          label="Api Endpoint Route"
          placeholder="Route"
          input-classes="form-control-alternative"
          v-model="apiEndpoint.api_endpoint"
        ></base-input>
        <base-dropdown class="mt-2 mb-3">
          <base-button slot="title" type="secondary" class="dropdown-toggle">{{
            service
          }}</base-button>
          <a
            class="dropdown-item"
            v-for="service in services"
            :key="service._id"
            @click="selectService(service)"
            href="#"
            >{{ service.name }}</a
          >
        </base-dropdown>
        <base-dropdown class="mt-2 mb-3">
          <base-button slot="title" type="secondary" class="dropdown-toggle">{{
            method
          }}</base-button>
          <a
            class="dropdown-item"
            v-for="method in ['GET', 'POST', 'PUT', 'DELETE']"
            :key="method"
            @click="selectMethod(method)"
            href="#"
            >{{ method }}</a
          >
        </base-dropdown>
        <base-input
          alternative
          class="mb-3"
          label="Api Endpoint Description"
          placeholder="Description"
          input-classes="form-control-alternative"
          v-model="apiEndpoint.description"
        ></base-input>
        <base-input
          alternative
          class="mt-2 mb-3"
          label="Hits Interval (s)"
          placeholder="e.g 60"
          input-classes="form-control-alternative"
          v-model="apiEndpoint.hits_interval"
        ></base-input>
        <base-input
          alternative
          class="mt-2 mb-3"
          label="Max hits within interval"
          placeholder="e.g 10"
          input-classes="form-control-alternative"
          v-model="apiEndpoint.max_hits"
        ></base-input>
        <div class="text-center">
          <base-button
            id="button-add"
            type="primary"
            class="my-4"
            @click="saveEndpoint"
            >{{ id ? "Update Endpoint" : "Add Endpoint" }}</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";
export default {
  name: "CreateApiEndpoints",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      apiEndpoint: {},
      method: "Select Method",
      service: "Select Service",
      services: []
    };
  },
  methods: {
    selectMethod(method) {
      this.apiEndpoint.api_method = method;
      this.method = method;
    },
    selectService(service) {
      this.apiEndpoint.micro_srvice = service._id;
      this.service = service.name;
    },
    async saveEndpoint() {
      let res;
      if (this.id) {
        res = await api.editApiEndpoint(this.id, this.apiEndpoint);
      } else {
        res = await api.addApiEndpoint(this.apiEndpoint);
      }
      if (res.success) {
        this.$confirm({
          title: "Success",
          message:
            "Api Endpoint Successfully " + this.id ? "Updated" : "Created",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Api Endpoints" });
          },
        });
      } else {
        this.$confirm({
          title: "Error",
          message:
            "Api Endpoint Error",
          button: {
            yes: "OK",
          },
        });
      }
    },
    async getApiEndpoint(id) {
      const res = await api.getApiEndpoint(id);
      if (res && res.data) {
        this.apiEndpoint = res.data.apiEndpoint;
        this.method = res.data.apiEndpoint.api_method;
        this.service = res.data.apiEndpoint.micro_srvice.name;
        this.apiEndpoint.micro_srvice = res.data.apiEndpoint.micro_srvice._id;
        delete this.apiEndpoint._id;
      }
    },
    async getAllServices() {
      const res = await api.getAllServices();
      if (res && res.data) {
        this.services = res.data.service;
      }
    },
  },
  mounted() {
    this.getAllServices();
    if (this.id) {
      this.getApiEndpoint(this.$route.params.id);
    }
  },
};
</script>
<style></style>
