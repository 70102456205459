<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>ID</th>
          <th>user-id</th>
          <th>API ENDPOINT</th>
          <th>BROWSER-PAGE</th>
        </template>
        <template slot-scope="{ row, index }">
          <th scope="row">{{ index + 1 }}</th>   
          <td class="budget">{{ row._source.user_id }}</td>
          <td>{{ row._source.apiEndpoint }}</td>
          <td>{{row._source.browserPage}}</td>
          <td class="budget">
          <span @click="nextItemClick(row)"> 
          </span>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
//import * as api from "@/apiList";

export default {
  name: "api-hits-record-table",
  props: {
    type: {
      type: String,
    },
    tableData: Array,
    title: String,
  },
  data() {
    return {
    };
  },
  mounted() {
    setTimeout(() => {
       console.log(this.tableData);
    }, 10000);
  },
  methods: {
      nextItemClick() {
      this.$router.push({
        name: "records",
      });
    },
  },
};
</script>
<style>
</style>
