<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
       <router-link to="/save-user-data" class="col-2 text-right">
          <base-button id="button-add" type="primary" >Add User</base-button>
          </router-link>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>ID</th>
          <th>Full Name</th>
          <th>Email</th>
        </template>
        <template slot-scope="{ row, index }">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ row.name }}</td>
          <td>{{ row.email }}</td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "admin-users-table",
  props: {
    type: {
      type: String,
    },
    tableData: Array,
    title: String,
  },
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
  },
};
</script>
<style>
</style>
