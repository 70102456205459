<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <form role="form" style="width: 400px; margin-top: 50px">
        <base-input
          alternative
          class="mb-3"
          label="User Name"
          placeholder="User Name"
          input-classes="form-control-alternative"
          v-model="user.name"
        ></base-input>
        <base-input
          alternative
          class="mb-3"
          label="Email"
          placeholder="Email"
          input-classes="form-control-alternative"
          v-model="user.email"
        ></base-input>
        <base-input
          alternative
          class="mb-3"
          type="password"
          label="Password"
          placeholder="Password"
          input-classes="form-control-alternative"
          v-model="user.password"
        ></base-input>
        <div>
          <base-checkbox
            class="mb-3"
            v-for="role in roles"
            :key="role._id"
            v-model="role.isSelected"
          >
            {{ role.role_name }}
          </base-checkbox>
        </div>
      </form>
    </div>
    <div class="text-center">
      <base-button
        id="button-add"
        type="primary"
        class="my-4"
        @click="saveUser"
        >{{ "Add User Role" }}</base-button
      >
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";
// import lodash from "lodash";
export default {
  name: "CreateApiEndpoints",
  components: {},
  data() {
    return {
      user: {},
      roles: [],
      selectedRoles: [],
    };
  },
    methods: {
   async getRoles() {
      const roles = await api.getRoles("");
      if (roles) {
        this.roles = roles.data.roles;
      }
    },
    async saveUser() {
      let selectedRole = [];
      this.roles.forEach((role) => {
        if (role.isSelected) {
          selectedRole.push(role._id);
        }
      });
      this.user.role = selectedRole;
      let res;
       res = await api.createUser(this.user );
      if (res.success) {
        this.$confirm({
          title: "Success",
          message: "Role Successfully " + this.id ? "added" : "Created",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Users" });
          },
        });
      } else {
        this.$confirm({
          title: "Error",
          message: "Role Error",
          button: {
            yes: "OK",
          },
        });
      }
    },
  },
    mounted() {
    this.getRoles();
  },
};
</script>
<style></style>
