<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    > 
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <base-button id="button-add" type="primary" @click="fileChooser" >Import</base-button>
        <router-link to="/create-users" class="col-2 text-right">
          <base-button id="button-add" type="primary" >Add User</base-button>
          </router-link>
      </div>  
    </div>
    <input class="form-control" type="text"
        v-model="search" placeholder="Search" aria-label="Search"/>
    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>ID</th>
          <th>PG ID </th>
          <th>Full Name</th>
          <th>Email</th>
          <th>Action</th> 
        </template>
  
        <template slot-scope="{ row, index }">
          <th scope="row">{{ index + 1 }}</th>
          <td class="budget">{{ row.pg_user_id ? row.pg_user_id : row.admin_user_id }}</td>
          <td>{{ row.name }}</td>
          <td>{{ row.email }}</td>                                                                                                       

          <td class="budget">
            <span @click="nextItemClick(row)">
              <i class="fa fa-database"></i>
            </span>
            <span @click="editItemClick(row)"
              style="margin-left: 10px">
              <i class="fa fa-edit"></i>
            </span>
            <span
              @click="deleteRole(row, index)"
              style="margin-left: 10px"
            >
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";

export default {
  name: "users-table",
  props: {
    type: {
      type: String,
    },
    tableData: Array,
    title: String,
  },
  data() {
    return {
      deleteModal: false,
       search:""  
    };
  },
  watch: {
        search: function (search) {
           this.$emit("dataUpdate" , 1, search);
        }

      },
  mounted() {
  },  
  methods: {
    async deleteRole(role) {
      this.$confirm({
        auth: false,
        title: "Delete User",
        message: `Are you sure you want to delete user?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm == true) {
            await api.deleteRole(role._id);
            this.$emit("dataUpdate" , "");
          }
        },
      });
    },
    editItemClick(role) {
      this.$router.push({
        name: "Edit Users",
        params: { id: role._id },
      });
    },
      nextItemClick(role) {

      this.$router.push({
        name: "records",
        params: { userid: role.pg_user_id },
      });
    },
    async fileChooser() {
      try {
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".csv";
        input.onchange = async (e) => {
          if (parseInt(e.target.files.length) > 1) {
            alert("You can only upload a single CSV file");
          } else {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);
            try {
              let res = await api.importUsersCsv(formData);
              if (res) {
         //   this.getCSVUploadResults(res);
          this.$emit("dataUpdate" , "");
                this.$confirm({
                  title: "Success",
                  message: "CSV file imported successfully",
                  button: {
                    yes: "OK",
                  },
                });
              }
            } catch (error) {
              alert(error.response.data.message);
            }
          }
        };
        input.click();
      } catch (e) {
        alert(e.message);
      }
    },
  },
};
</script>
<style>
</style>
