<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <router-link to="/create-services" class="col-2 text-right">
          <base-button id="button-add" type="primary">Add Service</base-button>
        </router-link>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Action</th>
        </template>

        <template slot-scope="{ row, index }">
          <th scope="row">{{ index + 1 }}</th>
          <td class="budget">{{ row.name }}</td>

          <td>{{ row.description }}</td>

          <td class="budget">
            <span @click="editItemClick(row)">
              <i class="fa fa-edit"></i>
            </span>
            <span @click="deleteService(row, index)" style="margin-left: 10px">
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";

export default {
  name: "services-table",
  props: {
    type: {
      type: String,
    },
    tableData: Array,
    title: String,
  },
  data() {
    return {
      deleteModal: false,
      method: "Select Method",
    };
  },
  mounted() {},
  methods: {
    async deleteService(service) {
      this.$confirm({
        auth: false,
        title: "Delete Service",
        message: `Are you sure you want to delete Service?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm == true) {
            const res = await api.deleteService(service._id);
            if (res && !res.success) {
              this.$confirm({
                  title: "Error",
                  message: res.message,
                  button: {
                    yes: "OK",
                  },
                });
            } else {
              this.$emit("dataUpdate");
            }
          }
        },
      });
    },
    editItemClick(service) {
      this.$router.push({
        name: "Edit Services",
        params: { id: service._id },
      });
    },
  },
};
</script>
<style>
</style>
