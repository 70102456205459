<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-0 pt-5 pt-md-0"></base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col">
          <roles-table title="Roles" :tableData="roles" @dataUpdate="getRoles"></roles-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RolesTable from "../Tables/RolesTable";

import * as api from "@/apiList";
export default {
  name: "ApiEndpoints",
  components: {
    RolesTable
  },
  data() {
    return {
      roles: []
    };
  },
  methods: {
    async getRoles(data) {
      const res = await api.getRoles(data);
      if(res.data) {
        this.roles = res.data.roles;
      }
    }
  },
  mounted() {
    this.getRoles("") ;
  }
};
</script>
<style></style>
