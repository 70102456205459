<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <form role="form" style="width: 400px; margin-top: 50px">
        <base-input
          alternative
          class="mb-3"
          label="Service Name"
          placeholder="Name"
          input-classes="form-control-alternative"
          v-model="service.name"
        ></base-input>
        <base-input
          alternative
          class="mb-3"
          label="Service Description"
          placeholder="Description"
          input-classes="form-control-alternative"
          v-model="service.description"
        ></base-input>
        <div class="text-center">
          <base-button
            id="button-add"
            type="primary"
            class="my-4"
            @click="saveService"
            >{{ id ? "Update Service" : "Add Service" }}</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";
export default {
  name: "CreateServices",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      service: {},
    };
  },
  methods: {
    async saveService() {
      let res;
      if (this.id) {
        res = await api.editService(this.id, this.service);
      } else {
        res = await api.addService(this.service);
      }
      if (res.success) {
        this.$confirm({
          title: "Success",
          message:
            this.id ? "Service Successfully Updated" : "Service Successfully Created",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Services" });
          },
        });
      } else {
        this.$confirm({
          title: "Error",
          message:
            "Service Error",
          button: {
            yes: "OK",
          },
        });
      }
    },
    async getservice(id) {
      const res = await api.getService(id);
      if (res && res.data) {
        this.service = res.data.service;
        delete this.service._id;
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getservice(this.$route.params.id);
    }
  },
};
</script>
<style></style>
