<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <form role="form" style="width: 400px; margin-top: 50px">
        <base-input
          alternative
          class="mb-3"
          label="User Name"
          placeholder="User Name"
          input-classes="form-control-alternative"
          v-model="user.name"
        ></base-input>
        <base-input
          alternative
          class="mb-3"
          label="Email"
          placeholder="Email"
          input-classes="form-control-alternative"
          v-model="user.email"
        ></base-input>
        <div class="mb-3">
          <label class="form-control-label" :class="labelClasses">
              Select Niches
          </label>
          <model-list-select
            :list="niches"
            v-model="selectNiches"
            option-value="id"
            option-text="name"
            placeholder="Search Niche"
          >
          </model-list-select>
        </div>
        <div class="mb-3">
          <label class="form-control-label" :class="labelClasses">
              Select Course
          </label>
          <model-list-select
            :list="courses"
            v-model="selectCourses"
            option-value="id"
            option-text="name"
            placeholder="Search Course"
          >
          </model-list-select>
        </div>
        <div>
          <base-checkbox
            class="mb-3"
            v-for="role in roles"
            :key="role._id"
            v-model="role.isSelected"
          >
            {{ role.role_name }}
          </base-checkbox>
        </div>
      </form>
    </div>
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col">
          <selected-item-table
            title="Selected Niches"
            :tableData="selectedNiches"
            @deleteSelectedItem="deleteSelectedNiche"
          ></selected-item-table>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col">
          <selected-item-table
            title="Selected Courses"
            :tableData="selectedCourses"
            @deleteSelectedItem="deleteSelectedCourse"
          ></selected-item-table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <base-button
        id="button-add"
        type="primary"
        class="my-4"
        @click="saveUser"
        >{{ "Update User Role" }}</base-button
      >
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";
import lodash from "lodash";
import { ModelListSelect } from "vue-search-select";
import SelectedItemTable from "../Tables/SelectedItemTable.vue";

export default {
  name: "CreateApiEndpoints",
  components: {
    SelectedItemTable,
    ModelListSelect
  },
  data() {
    return {
      id: this.$route.params.id,
      user: {},
      roles: [],
      niches: [],
      courses: [],
      selectNiches: {},
      selectCourses: {},
      selectedRoles: [],
      selectedNiches: [],
      selectedCourses: [],
    };
  },
  methods: {
    async getRoles() {
      const user = await api.getUser(this.id);
      const roles = await api.getRoles("");
      if (roles.success) {
        this.roles = roles.data.roles;
        this.user = user.data.user;
        user.data.user.role.forEach((role) => {
          const roleFind = lodash.findLast(this.roles, {
            _id: role,
          });
          if (roleFind) {
            roleFind.isSelected = true;
          }
        });

        user.data.admin_tags.forEach((selected) => {
          if(selected.course_id) {
            const foundItem = this.courses.find(item => item.id === selected.course_id);
            this.selectedCourses.push(foundItem);
          } else if(selected.tag_id) {
            const foundItem = this.niches.find(item => item.id === selected.tag_id);
            this.selectedNiches.push(foundItem);
          }
        });
      }
    },

    async getNiches() {
      const tags = await api.getTags();
      if (tags.success) {
        this.niches = tags.courseTags;
      }
    },

    async getCourse() {
      const courses = await api.getCourses();
      if (courses.success) {
        this.courses = courses.res;
      }
    },

    deleteSelectedNiche(index) {
      this.selectedNiches.splice(index, 1);
    },

    deleteSelectedCourse(index) {
      this.selectedCourses.splice(index, 1);
    },

    async saveUser() {
      let selectedRole = [];
      this.roles.forEach((role) => {
        if (role.isSelected) {
          selectedRole.push(role._id);
        }
      });
      let res;
      if (this.id) {
        res = await api.editUserRoles(this.id, { roles: selectedRole, selectedCourses: this.selectedCourses, selectedNiches: this.selectedNiches });
      } else {
        res = await api.addRole(this.role);
      }
      if (res.success) {
        this.$confirm({
          title: "Success",
          message: "Role Successfully " + this.id ? "Updated" : "Created",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Users" });
          },
        });
      } else {
        this.$confirm({
          title: "Error",
          message: "Role Error",
          button: {
            yes: "OK",
          },
        });
      }
    },
  },
  mounted() {
    this.getRoles();
    this.getNiches();
    this.getCourse();
  },

  watch: {
    selectNiches: function (val) {
      this.selectedNiches.push(val);
    },
    selectCourses: function (val) {
      this.selectedCourses.push(val);
    },
  },
};
</script>
<style></style>
