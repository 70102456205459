<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <h1>Forbidden</h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "CreateApiEndpoints",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style></style>
