<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <form role="form" style="width: 400px; margin-top: 50px">
        <base-input
          alternative
          class="mb-3"
          label="Email"
          placeholder="Email"
          input-classes="form-control-alternative"
          v-model="user.email"
        ></base-input>
        <base-input
          alternative
          class="mb-3"
          label="Name"
          placeholder="Name"
          input-classes="form-control-alternative"
          v-model="user.name"
        ></base-input>
           <base-input
          alternative
          class="mb-3"
          label="Password"
          type="password"
          placeholder="Password"
          input-classes="form-control-alternative"
          v-model="user.password"   
        ></base-input>
        <div class="text-center">
          <base-button
            id="button-add"
            type="primary"
            class="my-4"
            @click="addUser"
            >Add User</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";
export default {
  name: "CreateFrontendRoutes",
  components: {},
  data() {
    return {
      user: {},
      service: "Select Service",
      services: []
    };
  },
  methods: {
    async addUser(res) {
    
      if (res) {
        res = await api.addUser(this.user);
      } 
      if (res.success) {
        this.$confirm({
          title: "Success",
          message:
           "User can be added successfully",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Admin User" });
          },
          
        });
      } else {
        this.$confirm({
          title: "Error",
          message:
            "Error while user adding",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Admin User" });
          },
        });
      }
    },
  },
};
</script>
<style></style>
