<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div style="display: flex; justify-content: center">
      <form role="form" style="width: 400px; margin-top: 50px">
        <base-input
          alternative
          class="mb-3"
          label="Frontend Route Name"
          placeholder="Name"
          input-classes="form-control-alternative"
          v-model="frontendRoute.title"
        ></base-input>
        <base-input
          alternative
          class="mb-3"
          label="Frontend Route"
          placeholder="Route"
          input-classes="form-control-alternative"
          v-model="frontendRoute.route"
        ></base-input>
        <base-dropdown class="mt-2 mb-3">
          <base-button slot="title" type="secondary" class="dropdown-toggle">{{
            service
          }}</base-button>
          <a
            class="dropdown-item"
            v-for="service in services"
            :key="service._id"
            @click="selectService(service)"
            href="#"
            >{{ service.name }}</a
          >
        </base-dropdown>
        <base-input
          alternative
          class="mb-3"
          label="Frontend Route Description"
          placeholder="Description"
          input-classes="form-control-alternative"
          v-model="frontendRoute.description"
        ></base-input>
        <div class="text-center">
          <base-button
            id="button-add"
            type="primary"
            class="my-4"
            @click="saveFrontendRoute"
            >{{ id ? "Update Route" : "Add Route" }}</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";
export default {
  name: "CreateFrontendRoutes",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      frontendRoute: {},
      service: "Select Service",
      services: []
    };
  },
  methods: {
    selectService(service) {
      this.frontendRoute.service = service._id;
      this.service = service.name;
    },
    async saveFrontendRoute() {
      let res;
      if (this.id) {
        res = await api.editFrontendRoute(this.id, this.frontendRoute);
      } else {
        res = await api.addFrontendRoute(this.frontendRoute);
      }
      if (res.success) {
        this.$confirm({
          title: "Success",
          message:
            this.id ? "Frontend Routes Successfully Updated" : "Frontend Routes Successfully Created",
          button: {
            yes: "OK",
          },
          callback: () => {
            this.$router.push({ name: "Frontend Routes" });
          },
        });
      } else {
        this.$confirm({
          title: "Error",
          message:
            "Frontend Route Error",
          button: {
            yes: "OK",
          },
        });
      }
    },
    async getFrontendRoute(id) {
      const res = await api.getFrontendRoute(id);
      if (res && res.data) {
        this.frontendRoute = res.data.frontendRoute;
        this.service = res.data.frontendRoute.service.name;
        this.frontendRoute.service = res.data.frontendRoute.service._id;
        delete this.frontendRoute._id;
      }
    },
    async getAllServices() {
      const res = await api.getAllServices();
      if (res && res.data) {
        this.services = res.data.service;
      }
    },
  },
  mounted() {
    this.getAllServices();
    if (this.id) {
      this.getFrontendRoute(this.$route.params.id);
    }
  },
};
</script>
<style></style>
