<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-3 py-lg-5">
          <div class="text-center text-muted mb-4">
            <h2>Sign In</h2>
          </div>
          <form role="form">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-hat-3"
              v-model="data.email"
            ></base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="data.password"
            ></base-input>

            <div class="text-center">
              <base-button type="primary" class="my-4" @click="login"
                >Sign in</base-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/apiList";

export default {
  name: "login",
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      if (this.data.username === "") {
        alert("Email required");
      } else if (this.data.password === "") {
        alert("Password required");
      } else {
        const res = await api.signinUser(this.data);
        if (res.success) {
          await localStorage.setItem("isLoggedin", "Y");
          await localStorage.setItem("uid", res.user.user_id);
          this.$router.push("/dashboard");
        } else {
          alert("Invalid Username or Password");
        }
      }
    },
  },
};
</script>
<style>
</style>
