<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-0 pt-5 pt-md-0"
    ></base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col">
          <admin-users-table
            title="Admin Users"
            :tableData="Adminusers"
          ></admin-users-table>
        </div>
      </div>
      <div class="mt-2">
        <base-pagination
          :page-count="totalPages"
          v-model="currentPage"
          align="end"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import AdminUsersTable from "../Tables/AdminUserTable";

import * as api from "@/apiList";
export default {
  name: "AdminUsers",
  components: {
    AdminUsersTable,
  },
  data() {
    return {
      Adminusers: [],
      currentPage: 1,
      totalPages: 0,
    };
  },
  methods: {
       async getUserRecord() {
      const res = await api.getUserRecord();
      if (res) {
         this.Adminusers = res.data.user; 
      }
    },
  },
  mounted() {
    this.getUserRecord();
  },
};
</script>
<style></style>
